import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import LightboxImage from '../../components/LightboxImage'
import ReactHtmlParser from 'react-html-parser'

import Layout from './../../components/Layout'
import './css.css'
import {Col, Grid, Row} from "react-flexbox-grid";

export class view extends Component {
  render() {
    const passedData = this.props.location.state || {
      title: 'default title',
      description: 'default description',
      image: 'https://via.placeholder.com/350',
      author: 'default author',
    };

    const {title, description, image, author} = passedData;

    return (
        <Layout>
            <Helmet>
                <title>{`${author} | ${title}`}</title>
            </Helmet>
            <Link to="/galeries">&larr; Retour</Link>

            <h2 className={'galerieViewAuthor'}>{author}</h2>
            <h2 className={'galerieViewTitle'}>{title}</h2>
            <div className={'galerieViewDescription'}
                 style={{
                     width: '100%'
                 }}>


                <Grid fluid>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} key={{title} + 'oeuvre'}>
                            <LightboxImage
                                src={image}
                                author={author}
                                title={title}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} key={{title} + 'description'}>
                            {description && (
                                <div>
                                    {ReactHtmlParser(description)}
                                </div>
                            )}
                        </Col>
                    </Row>
                </Grid>
            </div>
        </Layout>
    )
}
}

export default view